<template>
  <div class="col-12">
    <div class="card">
      <h5>Ver Detalle Producto</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <label for="">C&Oacute;DIGO ORIGEN:</label>
          <InputText type="text" disabled v-model="producto.codigo_origen" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">C&Oacute;DIGO INTERNO:</label>
          <InputText type="text" disabled v-model="producto.codigo_interno" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">C&Oacute;DIGO EQUIVALENTE:</label>
          <InputText type="text" disabled v-model="producto.codigo_equivalente" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">C&Oacute;DIGO BARRA:</label>
          <InputText type="text" disabled v-model="producto.codigo_barra " />
        </div>
        <div class="field col-12 md:col-6">
          <label for="">PRODUCTO/DESCRIPCI&Oacute;N:</label>
          <InputText type="text" disabled v-model="producto.descripcion" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">UNIDAD DE MEDIDA:</label>
          <InputText type="text" disabled v-model="producto.unidad_medida_nombre" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">DIMENSIONES:</label>
          <InputText type="text" disabled v-model="producto.medidas" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">GRUPO PRODUCTO:</label>
          <InputText type="text" disabled v-model="producto.grupo_nombre" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">MARCA:</label>
          <InputText type="text" disabled v-model="producto.fabrica_nombre" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">PROCEDENCIA:</label>
          <InputText type="text" disabled v-model="producto.procedencia_nombre" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">STOCK M&Iacute;NIMO:</label>
          <InputText type="text" disabled v-model="producto.stock_minimo" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">STOCK M&Aacute;XIMO:</label>
          <InputText type="text" disabled v-model="producto.stock_maximo" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">FECHA VENCIMIENTO:</label>
          <Calendar type="text" disabled v-model="producto.fecha_vencimiento" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">PRECIO COMPRA:</label>
          <InputText type="text" disabled v-model="producto.precio_compra" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">PRECIO VENTA:</label>
          <InputText type="text" disabled v-model="producto.precio_sugerido" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">ESTADO:</label>
          <InputText type="text" disabled v-model="texto_estado" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">FECHA CREACI&Oacute;N:</label>
          <InputText type="text" disabled v-model="producto.created_at" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">FECHA ACTUALIZACI&Oacute;N:</label>
          <InputText type="text" disabled v-model="producto.updated_at" />
        </div>
        <div class="field col-6">
          <label for="">OBSERVACIONES:</label>
          <Textarea type="text" disabled v-model="producto.caso_uso" />
        </div>
        <div class="field col-6">
          <label for="">UBICACI&Oacute;N:</label>
          <Textarea type="text" disabled v-model="producto.ubicacion" />
        </div>
        <div class="field col-12 md:col-1">
          <Button @click="goBack">
            <i class="pi pi-arrow-circle-left"></i>
            &nbsp;ATR&Aacute;S
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductService from '@/service/ProductService';
export default {
  data() {
    return {
      producto: {},
      texto_estado: '',
      idProducto: this.$route.params.id,
    };
  },
  productoService: null,
  created() {
    this.productoService = new ProductService();
  },
  mounted() {
    this.productoService.showProducto(this.idProducto).then((data) => {
      this.producto = data;
      this.texto_estado = this.textoEstado(data.estado);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
};
</script>